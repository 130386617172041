@use 'partials' as *;

.componentStyle {
  display: block;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: size(48);
}

.modalTitle {
  font-size: size(18);
  font-weight: bold;
}

.modalDescription {
  margin-top: size(32);
}

.buttonContainer {
  display: flex;
  flex-flow: column nowrap;
  gap: size(16);
  align-items: center;
  width: size(254);
  margin-top: size(40);
}

.button {
  display: flex;
  gap: size(16);
  align-items: center;
  width: 100%;
  padding: size(12) size(24);
  background: var(--color-white);
  border: size(2) solid var(--color-darkgray);
  border-radius: size(40);
}

.icon {
  width: size(32);
  height: size(32);
  border-radius: 50%;
}

.buttonText {
  display: block;
  display: flex;
  justify-content: center;
  width: 100%;
}

// PC
@include media-min(pc) {
  .modalTitle {
    font-size: size(24);
  }

  .buttonContainer {
    width: size(320);
  }

  .button {
    padding: size(12) size(40);
    border-radius: size(40);
  }
}
