@use 'partials' as *;

.componentStyle {
  position: relative;
}

.splide {
  overflow: hidden;
}

.splideTrack {
  width: 100%;
  overflow: hidden;
}

.positions {
  display: flex;
  gap: size(5);
  justify-content: center;
  margin-top: size(10);
}

.positionItem {
  width: size(5);
  height: size(5);
  background-color: var(--color-lightgray);

  &.active {
    background-color: var(--color-primary);
  }
}

.itemLink {
  display: block;
  width: 100%;
  height: 100%;
}

.itemImg {
  display: block;
  width: 100%;
  object-fit: cover;
}

// PC
@include media-min(pc) {
  .splide {
    width: 100%;
  }

  .positions {
    bottom: size(-25);
    gap: size(10);
    width: 100%;
  }

  .positionItem {
    width: size(10);
    height: size(10);
  }
}
