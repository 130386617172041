@use 'partials' as *;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: size(24);
  @include media-max(sp) {
    flex-direction: column;
  }
}

.videoStyle {
  max-width: size(360);
  width: 100%;
}

// pc
@include media-min(pc) {
  .container {
    gap: size(84);
  }
  .videoStyle {
    width: size(360);
    height: auto;
  }
}
