@use 'partials' as *;

.componentStyle {
  position: fixed;
  z-index: $z-index-header;
  width: 100vw;
  height: size($header-height-sp);
  background-color: var(--color-white);
  top: var(--smart-banner-height);
}

.container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 size(8);
}

.logo {
  display: block;
  width: size(116);
  height: size(20);
}

.iconLogout {
  width: size(12);
  height: size(16);
}

.auth {
  display: flex;
  flex-flow: row nowrap;
  gap: size(24);
  align-items: center;
  font-size: size(12);
  letter-spacing: size(1);
}

.authLink {
  display: flex;
  gap: size(8);
  align-items: center;
}

// SP
@include media-max(sp) {
  .openMenuIcon {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    width: size(30);
    height: size(22);

    & > span {
      width: 100%;
      height: size(2);
      background-color: var(--color-black);
    }
  }

  .pcLinks {
    display: none;
  }
}

// PC
@include media-min(pc) {
  .componentStyle {
    height: size($header-height-pc);
    border-bottom: size(1) solid var(--color-warmgray);
  }

  .logo {
    width: size(234);
    height: size(40);
    margin-left: size(40);
  }

  .auth {
    font-size: size(14);
  }

  .pcLinks {
    display: flex;
    gap: size(24);
    align-items: center;
  }
}
