@use 'partials' as *;

.tableContainer {
  width: 100%;
  overflow-x: scroll;
}

.table {
  width: 100%;
}

.thead {
  background-color: var(--color-lightgray);
}

.th {
  padding: size(8);
  font-size: size(14);
  text-align: left;
}

.tr {
  border-bottom: size(1) solid var(--color-lightgray);
}

.td {
  padding: size(16) size(4);
  font-size: size(14);
}

.noneTd {
  display: none;
}

.alignRight {
  text-align: right;
}

.alignLeft {
  text-align: left;
}

.operationButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: size(16) 0;
}
