@use 'partials' as *;
@use 'sass:map';

.componentStyle {
  display: flex;
  flex-flow: row wrap;
  gap: size-vw(4);
  justify-content: center;
}

.postItemContainer {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: size-vw(168);
  cursor: pointer;
  background-color: var(--color-lightestgray);
  border-radius: size(8) size(8) 0 0;
}

.postImage {
  width: size-vw(168);
  height: size-vw(168);
  border-radius: size(8) size(8) 0 0;
  object-fit: contain;
}

.postInfoContainer {
  display: flex;
  flex-flow: column nowrap;
  gap: size(10);
  padding: size-vw(8);
}

.ellipsisBody {
  @include text-ellipsis-multi(2);

  font-size: size(12);
}

.artistNameCheerContainer {
  display: flex;
  gap: size-vw(2);
  align-items: center;
  justify-content: space-between;
  font-size: size(10);
}

.artistName {
  color: var(--color-darkgray);
  @include text-ellipsis;
}

.cheerIconText {
  display: flex;
  gap: size-vw(4);
  align-items: center;
  color: var(--color-primary);
}

.eventIconContainer {
  position: absolute;
  top: size-vw(8);
  right: size-vw(8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: size-vw(48);
  height: size-vw(48);
  background: rgba(map.get($colors, 'white'), 0.6);
  border-radius: size(8);
}

.eventIcon {
  width: size-vw(40);
  height: size-vw(40);
}

.borderFirstGachi {
  border-top: size(6) solid var(--color-yellow);
  border-right: size(6) solid var(--color-yellow);
}

.borderNewcomer {
  border-top: size(6) solid var(--color-green);
  border-right: size(6) solid var(--color-green);
}

.topLeftIconContainer {
  position: absolute;
  top: size-vw(0);
  left: size-vw(0);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3; /* 画像より前面に */
}

.topLeftIcon {
  width: size-vw(30);
  height: size-vw(30);
}
// PC
@include media-min(pc) {
  .componentStyle {
    gap: size(8);
  }

  .postItemContainer {
    width: size(192);
  }

  .postInfoContainer {
    padding: size(8);
  }

  .postImage {
    width: size(200);
    height: size(200);
    border-radius: size(8) size(8) 0 0;
    object-fit: contain;
  }

  .artistNameCheerContainer {
    gap: size(4);
  }

  .cheerIconText {
    gap: size(4);
  }

  .eventIconContainer {
    top: size(8);
    right: size(8);
    width: size(48);
    height: size(48);
  }

  .eventIcon {
    width: size(40);
    height: size(40);
  }

  .topLeftIcon {
    width: size-vw(6);
    height: size-vw(6);
  }
}
