@use 'partials' as *;

.tabItem {
  padding-bottom: size(10);
  font-size: size(14);
  text-align: center;
  cursor: pointer;
}

.inActive {
  border-bottom: size(1) solid var(--color-pinkishgray);
}

.active {
  border-bottom: size(2) solid var(--color-primary);
}

.disabled {
  color: var(--color-gray);
  cursor: default;
}

.tabs {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  // PC
  @include media-min(pc) {
    .tabItem {
      padding-bottom: size(20);
      font-size: size(18);
    }
  }
}

.tabText {
  position: relative;
}

.menuBadge {
  position: absolute;
  top: size(0);
  right: size(-10);
}
