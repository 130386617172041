@use 'partials' as *;

.linkButton {
  max-width: 100%;
  font-size: size(12);
  font-weight: bold;
  color: var(--color-white);
  white-space: nowrap;
  background-color: var(--color-dark);
  border: size(2) solid var(--color-dark);
  border-radius: size(40);

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.default {
  width: 100%;
  height: size(50);
}

.small {
  width: auto;
  height: size(40);
  padding: 0 size(16);
}

.wide {
  width: size-vw(104);
  height: size(32);
  font-size: size(16);
}

.fullWidth {
  width: 100%;
  height: size(50);
}

.autoWidth {
  width: auto;
  height: size(36);
  padding: size(16);
}

.link {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@include media-min(pc) {
  .default {
    width: size(400);
    height: size(60);
    font-size: size(18);
  }

  .small {
    width: size(400);
    height: size(50);
    padding: 0 size(24);
    font-size: size(18);
  }
}
