@import 'variables';

// 文字切れ3点リーダー（複数行）
@mixin text-ellipsis-multi($row: 1) {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

// 文字切れ3点リーダー（1行）
@mixin text-ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 文字切れ3点リーダー（消す）
@mixin text-ellipsis-remove() {
  overflow: initial;
  text-overflow: initial;
  white-space: initial;
  -webkit-line-clamp: initial;
  -webkit-box-orient: initial;
}

// ブレークポイント
$breakpoint-min: (
  pc: 'screen and (min-width: #{$width-sp}px)',
  // SPの最大幅より大きい場合
);
$breakpoint-max: (
  sp: 'screen and (max-width: #{$width-sp}px)',
  // SPの最大幅より小さい場合
);

// メディアクエリ
@mixin media-min($bp) {
  @media #{map-get($breakpoint-min, $bp)} {
    @content;
  }
}

@mixin media-max($bp) {
  @media #{map-get($breakpoint-max, $bp)} {
    @content;
  }
}
