@use 'partials' as *;

.input {
  display: block;
  width: 100%;
  padding: size(2);
  border: size(1) solid var(--color-lightgray);
  outline: none;
}

.wrapper {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  &.outlined {
    background-color: var(--color-white);
    border: size(1) solid var(--color-pinkishgray);
  }
}
