@use 'partials' as *;

.componentStyle {
  display: flex;
  flex-flow: column nowrap;
  gap: size(16);
  padding: size(24);
  background: var(--color-lightestgray);
  border-radius: size(8);
}

.itemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: size(16);
  border-bottom: size(1) solid var(--color-lightgray);
}

.itemValue {
  font-size: size(32);
  font-weight: bold;
}

.itemValueSmall {
  font-size: size(24);
  font-weight: bold;
}

.suffixText {
  font-size: size(16);
}
