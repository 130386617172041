@use 'partials' as *;

.componentStyle {
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  font-size: size(12);
  color: var(--color-white);
  letter-spacing: 0;
  border: none;
  border-radius: 50%;
}

.default {
  width: size(8);
  height: size(8);
}

.medium {
  width: size(24);
  height: size(24);
}

.large {
  width: size(28);
  height: size(28);
}

@include media-min(pc) {
  .componentStyle {
    font-size: size(14);
  }
}
