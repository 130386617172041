@use 'partials' as *;

.input {
  display: flex;
  width: 4em;
  height: 100%;
  font-size: inherit;
  font-weight: inherit;
  text-align: right;
  background: transparent;
  border: none;
  border-radius: 0;
  outline: none;
}

.inputSpan {
  font-weight: bold;
}
