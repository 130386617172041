@use 'partials' as *;

.searchIcon {
  margin: 0 0.5em;
}

// PC
@include media-min(pc) {
  .searchIcon {
    width: size(20);
    height: size(20);
  }
}
