@use 'partials' as *;

.componentStyle {
  width: 100%;
  height: size(180);
}

// PC
@include media-min(pc) {
  .componentStyle {
    width: 100%;
    height: size(500);
  }
}
