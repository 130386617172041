@use 'partials' as *;

.appearance {
  pointer-events: none;

  &::before {
    position: absolute;
    top: 30%;
    right: 0.5em;
    width: 0;
    content: '';
    border-right: size(3) solid transparent;
    border-bottom: size(4.5) solid var(--color-pinkishgray);
    border-left: size(3) solid transparent;
  }

  &::after {
    position: absolute;
    right: 0.5em;
    bottom: 30%;
    width: 0;
    content: '';
    border-top: size(4.5) solid var(--color-pinkishgray);
    border-right: size(3) solid transparent;
    border-left: size(3) solid transparent;
  }
}

.placeholder {
  color: var(--color-pinkishgray);
}

.selectWrapper {
  background-color: var(--color-white);
}

.outlined {
  border: size(1) solid var(--color-pinkishgray);
  border-radius: size(8);
}

.select {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 0.5em;
  font-size: inherit;
  font-weight: inherit;
  background: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  appearance: none;

  &.isNull {
    color: var(--color-pinkishgray);
  }
}

.smallRect {
  height: size(40) !important;
  font-size: size(12) !important;
}
