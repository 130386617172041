@use 'partials' as *;

.input {
  display: flex;
  width: 100%;
  height: 100%;
  font-size: inherit;
  font-weight: inherit;
  background: transparent;
  border: none;
  border-radius: 0;
  outline: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button,
  .input[type='number'] {
    margin: 0;
    appearance: textfield;
  }
}

.input[type='password'] {
  padding-right: 2.5em;
}

.smallRect {
  height: size(40) !important;
  font-size: size(12) !important;
}

.outlined {
  background-color: var(--color-white);
  border: size(1) solid var(--color-pinkishgray);
  border-bottom: size(1) solid var(--color-pinkishgray) !important;
}

.hint {
  margin-top: size(4);
  font-size: size(14);
}

// PC
@include media-min(pc) {
  .smallRect {
    height: size(32) !important;
    font-size: size(14) !important;
  }
}
