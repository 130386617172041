@use 'partials' as *;

.eventListContainer {
  display: flex;
  flex-flow: column nowrap;
  gap: size(8);
  margin-top: size(16);
}

// PC
@include media-min(pc) {
  .eventListContainer {
    display: flex;
    flex-flow: row wrap;
    gap: size(24);
    margin-top: size(32);
  }
}
