@use 'partials' as *;

.playerWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.video,
.audio {
  width: 100%;
}
