@use 'partials' as *;

.componentStyle {
  display: block;
  cursor: pointer;
}

.artistIcon {
  flex-shrink: 0;
  width: size(56);
  height: size(56);
}

.artistName {
  font-size: size(14);
  @include text-ellipsis-multi(3);

  font-weight: bold;
}

// SP
// だいぶレイアウトが変わるので、SPのみのスタイルを記述
@include media-max(sp) {
  .artistItemContainer {
    display: flex;
    gap: size(16);
    align-items: center;
    height: size(88);
    padding: size(16) 0;
    cursor: pointer;
    border-bottom: size(1) solid var(--color-gray);
  }
}

// PC
@include media-min(pc) {
  .componentStyle {
    display: flex;
    flex-flow: row wrap;
    gap: size(24);
    padding-top: size(10);
  }

  .artistItemContainer {
    display: flex;
    flex-flow: column nowrap;
    gap: size(16);
    align-items: center;
    width: size(220);
    height: size(150);
    padding-bottom: size(16);
    cursor: pointer;
    border-bottom: size(1) solid var(--color-gray);
  }

  .artistIcon {
    width: size(80);
    height: size(80);
  }

  .artistName {
    text-align: center;
    @include text-ellipsis-multi(2);
  }
}
