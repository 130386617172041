@use 'partials' as *;
@use 'sass:map';

.modalMask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-modal;
  width: 1000vw;
  height: 100vh;
  background: rgba(map.get($colors, 'black'), 0.8);
}

.modalContainer {
  position: fixed;
  top: 10%;
  left: 50%;
  width: 94vw;
  border: none;
  transform: translateX(-50%);
}

.modalClose {
  position: relative;
  top: auto;
  right: 0;
  left: auto;
  display: block;
  width: 2em;
  height: 2em;
  padding-right: 2.5em;
  padding-bottom: 5em;
  margin: 0 0 0 auto;
  font-size: size(11);
  background: none;
  border: none;
}

.modalBody {
  max-height: 70vh;
  padding: size(32);
  overflow-x: scroll;
  overscroll-behavior-y: contain;
  border-radius: size(8);
}

// PC
@include media-min(pc) {
  .modalContainer {
    width: size(600);
    max-width: 80vw;
    border-top-width: size(10) !important; // 太さ指定
  }

  .modalBody {
    max-height: 70vh;
    padding: size(48);
  }
}

// アニメーション関係
.modalClose::after,
.modalClose::before {
  position: absolute;
  top: calc(50% - 0.1em);
  display: block;
  width: 2em;
  height: size(1);
  content: '';
  background: var(--color-white);
}

.modalClose::before {
  transform: rotate(45deg);
}

.modalClose::after {
  transform: rotate(-45deg);
}

// 以下CSSTransitionで参照されるクラス
.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: opacity 0.3s, transform 0.3s;
  transform: translateX(0);
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
}
