@use 'partials' as *;

.componentStyle {
  width: 100vw;
  font-size: size(10);
  background-color: var(--color-lightestgray);
}

.serviceIcon {
  display: flex;
  width: size(100);
  height: size(100);
  border-radius: size(8);
}

.serviceDescription {
  margin-top: size(8);
  font-size: size(14);
  text-align: center;
}

.bannerContainer {
  display: flex;
  gap: size(16);
  justify-content: center;
  width: 100%;
  margin-top: size(24);
}

.appBanner {
  width: size(96);
  height: size(32);
}

.container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: size(48) size(8);
}

.menuList {
  display: flex;
  flex-flow: row wrap;
  gap: size(16);
  justify-content: center;
  width: 100%;
  margin: size(32) 0;
  font-size: size(12);
}

.copyright {
  width: 100%;
  font-size: size(10);
  text-align: center;
}

// PC
@include media-min(pc) {
  .componentStyle {
    min-width: size($width-pc);
    font-size: size(12);
  }

  .container {
    width: size($width-pc);
    padding: size(80) 0;
    margin: 0 auto;
  }
}
