@use 'partials' as *;

.modalContentsContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: size(18);
}

.logoContainer {
  width: size(130);
  height: auto;
}

.description {
  margin-top: size(32);
  font-size: size(14);
  font-weight: bold;
  text-align: center;
}

.linkButtonContainer {
  display: flex;
  gap: size(24);
  justify-content: center;
  width: 100%;
  margin-top: size(56);
}

.buttonDescriptionContainer {
  display: flex;
  flex-flow: column nowrap;
  gap: size(8);
  align-items: center;
}

.buttonDescription {
  font-size: size(10);
}

.appBanner {
  width: size(90);
  height: size(30);
}

.btnTextContainer {
  display: flex;
  gap: size(10);
  align-items: center;
}

// PC
@include media-min(pc) {
  .logoContainer {
    width: size(160);
  }

  .modalContentsContainer {
    padding: size(48);
  }

  .description {
    margin-top: size(40);
    font-size: size(16);
  }

  .buttonDescription {
    font-size: size(12);
  }

  .appBanner {
    width: size(120);
    height: size(40);
  }
}
