@use 'partials' as *;

.postListContainer {
  margin-top: size(16);
}

// PC
@include media-min(pc) {
  .postListContainer {
    margin-top: size(32);
  }
}
