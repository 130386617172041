@use 'partials' as *;

.componentStyle {
  display: flex;
  flex-flow: column nowrap;
  cursor: pointer;
}

.bannerImg {
  width: 100%;
  height: size(172);
  border-top-left-radius: size(8);
  border-top-right-radius: size(8);
}

.itemFooterContainer {
  display: flex;
  flex-flow: column;
  gap: size(8);
  padding: size(8);
  border-bottom-right-radius: size(8);
  border-bottom-left-radius: size(8);
}

.rankArtistIconContainer {
  display: flex;
  gap: size(4);
}

.rankArtistIcon {
  width: size-vw(64);
  height: size-vw(64);
}

.typeEndAtContainer {
  display: flex;
  justify-content: space-between;
  font-size: size(12);
  color: var(--color-white);
}

.endAtContainer {
  display: flex;
  gap: size(4);
  align-items: center;
}

.iconClock {
  width: size(11);
  height: size(12);
}

// PC
@include media-min(pc) {
  .componentStyle {
    width: size(384);
  }

  .rankArtistIcon {
    width: size(72);
    height: size(72);
  }
}
