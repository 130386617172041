/* stylelint-disable color-no-hex */

// 色
$colors: (
  'primary': #e78cb7,
  'error': #f00,
  'black': #000,
  'dark': #252b37,
  'gray': #c6cfd2,
  'darkgray': '#888',
  'pinkishgray': #bfbfbf,
  'lightgray': #dbdbdb,
  'warmgray': #999,
  'lightestgray': #f0f0f0,
  'light': #f4f4f4,
  'white': #fff,
  'blue': #81c8eb,
  'yellow': #facd91,
  'green': #acd598,
);

// 幅
$width-pc: 1200; // PCの幅。
$width-pc-medium: 1000; // PCの幅。（内部コンテナなど）
$width-pc-small: 640; // PCの幅。（内部コンテナなど）
$width-sp: 800; // SPの幅。※ ブレークポイントにも使用する。
$width-left-pc: 46%;
$width-center-pc: 2%;
$width-right-pc: 52%;

// ヘッダー高さ
$header-height-sp: 60;
$header-height-pc: 80;

// z-index
$z-index-header: 100;
$z-index-menu: 200;
$z-index-animation: 500;
$z-index-item-fab: 600;
$z-index-artist-fab: 600;
$z-index-modal: 700;
$z-index-swal: 1000;
