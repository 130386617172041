@use 'partials' as *;

.actionButton {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  font-weight: bold;
  color: var(--color-white);
  white-space: nowrap;
  background-color: var(--color-dark);
  border: size(2) solid var(--color-dark);
  border-radius: size(40);

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.default {
  width: 100%;
  height: size(50);
  font-size: size(15);
}

.xSmall {
  width: auto;
  height: size(25);
  padding: 0 size(16);
  font-size: size(10);
}

.small {
  width: size(120);
  height: size(40);
  padding: 0 size(16);
  font-size: size(15);
}

.fullWidth {
  width: 100%;
  height: size(50);
  font-size: size(15);
}

.loader,
.loader::before,
.loader::after {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  animation: loading 1.8s infinite ease-in-out;
  animation-fill-mode: both;
}

.loader {
  position: relative;
  top: -2.5em;
  font-size: size(4);
  color: currentcolor;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}

.loader::before,
.loader::after {
  position: absolute;
  top: 0;
  content: '';
}

.loader::before {
  left: -3.5em;
  animation-delay: -0.32s;
}

.loader::after {
  left: 3.5em;
}
@keyframes loading {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes loading {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@include media-min(pc) {
  .default {
    width: size(400);
    height: size(60);
    font-size: size(18);
  }

  .xSmall {
    width: auto;
    height: size(40);
    padding: 0 size(24);
    font-size: size(14);
  }

  .small {
    height: size(50);
    padding: 0 size(24);
  }
}
