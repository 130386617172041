@use 'partials' as *;

.componentStyle {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.iconLevelContainer {
  position: relative;
}

.levelContainer {
  position: absolute;
  bottom: size(-4);
  left: size(36);
  display: flex;
  align-items: center;
  justify-content: center;
  width: size(96);
  height: size(32);
  padding: size(6) 0;
  font-size: size(14);
  font-weight: bold;
  color: var(--color-white);
  background-color: var(--color-primary);
  border-radius: size(16);
}

.icon {
  width: size(160);
  height: size(160);
  border: size(4) solid var(--color-primary);
  border-radius: 50%;
}

.name {
  margin-top: size(24);
  font-size: size(16);
  font-weight: bold;
}

.birthday {
  margin-top: size(16);
  font-size: size(12);
}

.hr {
  margin: size(32) 0;
  background-color: var(--color-lightgray);
}

.linkItemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: size(16);
  margin-top: size(32);
  font-size: size(14);
  color: var(--color-primary);
  border-bottom: size(1) solid var(--color-lightgray);
}

.linkIcon {
  width: size(16);
  height: size(16);
}

.accordionProfileContainer {
  display: flex;
  flex-flow: column nowrap;
  gap: size(4);
  align-items: flex-end;
}

.toggleAccordionText {
  cursor: pointer;
}
