@use 'partials' as *;

/******
 * ページで使用するレイアウトのスタイル
 ******/

// ページ共通レイアウト
.pageWrapper {
  padding-top: calc(
    var(--header-height) + var(--smart-banner-height)
  ); /* ヘッダー＋スマートバナー分を確保 */
}

.page {
  min-height: 90vh;
  padding-top: size(20);
  padding-bottom: size(40);
  background-color: var(--color-white);
}

.topPage {
  padding-bottom: size(40);
  background-color: var(--color-white);
}

.fullHeightView {
  height: 100vh;
}

// くじ引きアニメーション用
.pageAnime {
  position: relative;
  height: 100vh;
  min-height: size(600);
  padding-top: size(20);
  padding-bottom: size(40);
  overflow: hidden;
}

.pageTitle {
  font-size: size(18);
  font-weight: bold;
  letter-spacing: 0.1em;
}

.pageContainer {
  width: auto;
  padding-right: 3.2vw;
  padding-left: 3.2vw;
}

.pageContainerSmall {
  padding-right: size(12);
  padding-left: size(12);
}

.pageContainerLarge {
  padding-right: size(0);
  padding-left: size(0);
}

.fullWidth {
  width: 100%;
}

// SP/PCで並び方向を変える
.mediaFlexFlow {
  display: flex;
  flex-flow: column nowrap;
  gap: size(32);
}

// SP
@include media-max(sp) {
  .fullSp {
    width: 100%;
  }

  .containerPaddingSp {
    padding-right: 3.2vw;
    padding-left: 3.2vw;
  }

  // SP/PCで並び方向を変える
  .mediaGridContainer {
    display: grid;
    grid-template-rows: auto size(32) auto;
  }

  .mediaGridContentFirst {
    grid-row: 1 / 2;
  }

  .mediaGridContentSecond {
    grid-row: 3 / 4;
  }
}

// PC
@include media-min(pc) {
  .pageWrapper {
    padding-top: size($header-height-pc);
  }

  .page {
    padding-top: size(60);
    padding-bottom: size(80);
  }

  .topPage {
    padding-bottom: size(80);
  }

  .pageAnime {
    padding-top: size(60);
    padding-bottom: size(80);
    background-color: var(--color-black);
  }

  .pageTitle {
    font-size: size(24);
    text-align: left;
  }

  .pageContainer {
    width: size($width-pc);
    min-width: size($width-pc);
    max-width: 100vw;
    padding: 0;
    margin-right: auto;
    margin-left: auto;
  }

  .pageContainerFlex {
    display: flex;
    gap: size(24);
  }

  .pageContainerSmall {
    width: size($width-pc-small);
    max-width: 100vw;
    margin: 0 auto;
  }

  .pageContainerLarge {
    width: size($width-pc);
    margin-right: auto;
    margin-left: auto;
  }

  .pcInnerContainer {
    padding: 0 size(148);
  }

  .mediaGridContainer {
    display: grid;
    grid-template-columns: size(320) size(40) 1fr;
  }

  .mediaGridContentFirst {
    grid-column: 1 / 2;
  }

  .mediaGridContentSecond {
    grid-column: 3 / 4;
  }
}

// モーダル共通レイアウト
.modalPadding {
  padding-right: 3.2vw;
  padding-bottom: size(20);
  padding-left: 3.2vw;

  // PC
  @include media-min(pc) {
    padding-right: size(24);
    padding-bottom: size(40);
    padding-left: size(24);
  }
}

.modalTitle {
  padding: size(40) 0;
  font-size: size(18);
  font-weight: bold;
  text-align: center;

  // PC
  @include media-min(pc) {
    padding: size(40) 0;
    font-size: size(24);
  }
}

// フォームページ用
.formWrapper {
  display: flex;
  flex-flow: column nowrap;
  gap: size(20);

  // PC
  @include media-min(pc) {
    gap: size(40);
  }
}

.centeredFormWrapper {
  display: flex;
  flex-flow: column nowrap;
  gap: size(20);
  align-items: center;

  // PC
  @include media-min(pc) {
    gap: size(40);
  }
}

.centeredBtnWrapper {
  display: flex;
  flex-flow: column nowrap;
  gap: size(20);
  align-items: center;
  justify-content: center;

  // PC
  @include media-min(pc) {
    gap: size(40);
  }
}

.smallGapBtnWrapper {
  display: flex;
  flex-flow: column nowrap;
  gap: size(10);
  align-items: center;
  justify-content: center;

  // PC
  @include media-min(pc) {
    gap: size(20);
  }
}
