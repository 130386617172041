@use 'partials' as *;

.noticeBlock {
  display: flex;
  flex-flow: column nowrap;
  gap: size(10);
  padding: size(8);
  border: solid size(1) var(--color-black);
  border-radius: size(8);
}

// PC
@include media-min(pc) {
  .noticeBlock {
    gap: size(20);
  }
}
