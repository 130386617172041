@use 'partials' as *;

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: size(16);
  font-size: size(24);
  font-weight: bold;
  letter-spacing: 0.1em;
  border-bottom: solid size(1) var(--color-lightgray);
}

.chevronRight {
  font-size: size(20);
  color: var(--color-darkgray);
  cursor: pointer;
}
