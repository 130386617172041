@use 'partials' as *;

.componentStyle {
  font-size: inherit;
  font-size: size(12);
  color: var(--color-black);
  word-break: break-all;
  word-wrap: break-word;
}

.required {
  color: inherit;
}

@include media-min(pc) {
  .componentStyle {
    font-size: size(14);
  }
}
