@use 'partials' as *;

.componentStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: size(100);
}

.loader,
.loader::before,
.loader::after {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  animation: loading 1.8s infinite ease-in-out;
  animation-fill-mode: both;
}

.loader {
  position: relative;
  top: -2.5em;
  font-size: size(4);
  color: var(--color-brownishgray);
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}

.loader::before,
.loader::after {
  position: absolute;
  top: 0;
  content: '';
}

.loader::before {
  left: -3.5em;
  animation-delay: -0.32s;
}

.loader::after {
  left: 3.5em;
}
@keyframes loading {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes loading {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
