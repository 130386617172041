@use 'partials' as *;

.componentStyle {
  position: relative;
  width: size(56);
  height: size(56);
}

.icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.other {
  border: solid size(2) var(--color-primary);
}

.newComer {
  border: solid size(4) var(--color-green);
}

.firstGachi {
  border: solid size(4) var(--color-yellow);
}

.additionalIcon {
  width: size(20);
  height: size(20);
  position: absolute;
  right: 0;
  bottom: 0;
}

// PC
@include media-min(pc) {
  .componentStyle {
    width: size(120);
    height: size(120);
  }

  .additionalIcon {
    width: size(40);
    height: size(40);
  }
}
