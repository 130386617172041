@use 'partials' as *;

.faqs {
  & > li {
    padding: size(20) 0;
    border-bottom: size(1) solid var(--color-pinkishgray);
  }
}

.faqTitle {
  font-size: size(20);
  font-weight: bold;
}

.description {
  padding-left: size(10);
  margin-top: size(10);
  font-size: size(16);
}

.categoryName {
  padding-left: size(10);
  margin-top: size(10);
}
