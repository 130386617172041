@use 'partials' as *;

.componentStyle {
  display: flex;
  gap: size(4);
  align-items: center;
}

.iconContainer {
  flex-shrink: 0;
  width: size(60);
  height: size(56);
}

.artistIconsContainer {
  display: flex;
  flex-flow: row wrap;
  flex-shrink: 0;
  gap: size(4);
}

.artistItemContainer {
  display: flex;
  flex-flow: column nowrap;
  gap: size(8);
  align-items: center;
  width: size(72);
  cursor: pointer;
}

.artistName {
  width: 100%;
  font-size: size(10);
  text-align: center;
  @include text-ellipsis;
}

.moreText {
  font-size: size(14);
  font-weight: bold;
  color: var(--color-darkgray);
  cursor: pointer;
}

// SPのみ
@include media-max(sp) {
  .exceedSpMaxNum {
    display: none;
  }
}

// PC
@include media-min(pc) {
  .componentStyle {
    gap: size(16);
  }

  .iconContainer {
    width: size(82);
    height: size(80);
  }

  .artistIconsContainer {
    gap: size(24);
  }

  .artistItemContainer {
    width: size(120);
  }

  .artistName {
    font-size: size(14);
  }

  .moreText {
    margin-left: size(16);
  }

  .exceedPcMaxNum {
    display: none;
  }
}
