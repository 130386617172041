@use 'partials' as *;

.inputCheckbox {
  display: flex;
  font-size: size(12);

  input {
    position: absolute;
    opacity: 0;
    appearance: none;

    &:checked + span::after {
      position: absolute;
      top: 0.4em;
      left: 0.3em;
      display: block;
      width: 1em;
      height: 0.6em;
      content: '';
      border-bottom: 0.15em solid var(--color-white);
      border-left: 0.15em solid var(--color-white);
      transform: rotate(-45deg);
    }

    &:checked + span::before {
      background-color: var(--color-primary);
      border-color: var(--color-primary);
    }
  }

  span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    /* stylelint-disable-next-line no-descending-specificity */
    &::before {
      display: block;
      width: 1.5em;
      height: 1.5em;
      margin-top: 0.1em;
      margin-right: 0.5em;
      content: '';
      border: size(2) solid var(--color-pinkishgray);
      border-radius: size(2);
    }
  }
}

.manualChecked + .span::after {
  position: absolute;
  top: 0.2em;
  left: 0.3em;
  display: block;
  width: 1.5em;
  height: 0.6em;
  content: '';
  border-bottom: 0.2em solid var(--color-primary);
  border-left: 0.2em solid var(--color-primary);
  transform: rotate(-45deg);
}

// PC
@include media-min(pc) {
  .inputCheckbox {
    font-size: size(14);
  }
}
