@use 'partials' as *;

.componentStyle {
  display: block;
}

.annotation {
  margin-top: size(16);
  font-size: size(12);
}
