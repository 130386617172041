@use 'partials' as *;

.componentStyle {
  display: block;
}

.rankItemContainer {
  display: flex;
  gap: size(16);
  align-items: flex-start;
  padding: size(16) 0;
}

.artistIcon {
  position: relative;
  flex-shrink: 0;
}

.ranker {
  width: size(88);
  height: size(88);
}

.others {
  width: size(64);
  height: size(64);
}

.rankBadge {
  position: absolute;
  bottom: size(0);
  left: size(2);
}

.rankerCrown {
  position: absolute;
  top: size(-10);
  right: size(-10);
  width: size(40);
  height: size(30);
}

.rankerRankTextContainer {
  position: absolute;
  bottom: size(-10);
}

.bgRibbon {
  position: relative;
  width: size(88);
  height: size(28);
}

.rankerRankText {
  position: absolute;
  top: size(-2);
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: size(16);
  color: var(--color-white);
}

.artistName {
  font-size: size(16);
  @include text-ellipsis;

  font-weight: bold;
}

.detailAreaContainer {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  gap: size(8);
}

.scoreContainer {
  display: flex;
  gap: size(8);
}

.scoreIcon {
  width: size(24);
  height: size(18);
}

.scoreText {
  font-size: size(16);
  color: var(--color-darkgray);
}

.fanRanksContainer {
  display: grid;
  grid-template-columns: size(20) size(8) 1fr auto;
  align-items: center;
}

.fanIcon {
  width: size(20);
  height: size(20);
}

.topRankFanName {
  grid-column: 3 / 4;
  @include text-ellipsis;

  color: var(--color-darkgray);
}

.otherRankFanContainer {
  display: flex;
  grid-column: 4 / 5;
  gap: size(8);
  align-items: center;
  justify-content: flex-end;
}

.rewardTextContainer {
  display: flex;
  justify-content: flex-end;
  color: var(--color-white);
  border-bottom: size(1) solid var(--color-gray);
}

// TODO: fontとかでも高さ変わるので::beforeにしたい
.triangle {
  width: size(27);
  height: size(27);
  clip-path: polygon(100% 0, 100% 100%, 0 100%);
  background-color: var(--color-primary);
}

.rewardText {
  padding: size(4) size(8);
  font-size: size(12);
  background-color: var(--color-primary);
}

// PC
@include media-min(pc) {
  .fanRanksContainer {
    grid-template-columns: size(40) size(8) 1fr auto;
  }

  .fanIcon {
    width: size(40);
    height: size(40);
  }
}
