@use 'partials' as *;

/******
 * FormXXX系のコンポーネントのスタイル
 ******/

.formComponentStyle {
  width: 100%;
  font-size: size(15);

  @include media-min(pc) {
    font-size: size(18);
  }
}

.fieldWrapper {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  height: size(50);
  border-bottom: size(1) solid var(--color-black);

  @include media-min(pc) {
    height: size(60);
  }
}

.validateErrors {
  font-size: inherit;
  color: var(--color-error);
}
