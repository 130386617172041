@use 'partials' as *;

.componentStyle {
  display: block;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.bodyText {
  margin-top: size(16);
}

.splide {
  overflow: hidden;
}

.progressBg {
  background: var(--color-brownishgray);
}

.progress {
  height: size(2);
  background: var(--color-light);

  &::-webkit-progress-value {
    background-color: var(--color-light);
  }
}

.positions {
  display: flex;
  gap: size(5);
  justify-content: center;
  margin-top: size(10);
}

.positionItem {
  width: size(5);
  height: size(5);
  background-color: var(--color-lightgray);

  &.active {
    background-color: var(--color-primary);
  }
}

.contentArea {
  width: 100%;
  height: size(560);
}

.contentVideoThumbnail {
  position: relative;
}

.playIcon {
  position: absolute;
  top: calc(50% - size(30));
  left: calc(50% - size(30));
  width: size(60);
  height: size(60);
}

// PC
@include media-min(pc) {
  .splide {
    width: size(640);
  }

  .positions {
    bottom: size(-25);
    gap: size(10);
    width: size(640);
  }

  .positionItem {
    width: size(10);
    height: size(10);
  }
}
