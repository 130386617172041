@use 'partials' as *;

.componentStyle {
  display: block;
}

.noMailAddressAnnotation {
  margin-bottom: size(10);
}

.formButtonUnit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prefixFormUnit {
  display: flex;
  align-items: center;
}
