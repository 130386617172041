@use 'partials' as *;

.smartBanner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: size(8) size(16);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-header + 1;
  height: size(50);
}

.content {
  display: flex;
  align-items: center;
  gap: size(12);
}

.appIcon {
  width: size(40);
  height: size(40);
  border-radius: size(8);
}

.text {
  display: flex;
  flex-direction: column;
  font-size: size(10);

  strong {
    font-size: size(12);
    font-weight: bold;
  }
}

.downloadButton {
  background-color: var(--color-accent);
  color: var(--color-white);
  padding: size(6) size(12);
  border-radius: size(4);
  text-decoration: none;
  font-size: size(10);
  font-weight: bold;
}

.closeButton {
  background: none;
  border: none;
  color: var(--color-white);
  font-size: size(20);
  cursor: pointer;
}

// SP
@include media-max(sp) {
  .smartBanner {
    padding: size(8);
  }

  .text {
    font-size: size(5);
  }

  .downloadButton {
    font-size: size(10);
  }
}
