@use 'partials' as *;

.componentStyle {
  display: flex;
  flex-flow: column nowrap;
  gap: size(8);
}

.eventItem {
  width: 100%;
}

// PC
@include media-min(pc) {
  .componentStyle {
    display: flex;
    flex-flow: row wrap;
    gap: size(24);
  }

  .eventItem {
    width: size(384);
  }
}
