@use 'partials' as *;
@use 'sass:map';

.componentStyle {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-menu;
  width: 100%;
  height: 100vh;
}

.container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  height: 100vh;
  padding: size(18) size(24) size(100) size(24);
  overflow: auto;
  overscroll-behavior-y: contain;
  color: var(--color-white);
  background-color: rgba(map.get($colors, 'black'), 0.95);
}

.modalClose {
  position: relative;
  right: 0;
  left: auto;
  display: block;
  width: 2em;
  height: 2em;
  padding-right: 2.5em;
  padding-bottom: 5em;
  margin: 0 0 0 auto;
  font-size: size(11);
  background: none;
  border: none;
}

.modalClose::after,
.modalClose::before {
  position: absolute;
  top: calc(50% - 0.1em);
  display: block;
  width: 2em;
  height: size(2);
  content: '';
  background: var(--color-white);
}

.modalClose::before {
  transform: rotate(45deg);
}

.modalClose::after {
  transform: rotate(-45deg);
}

.iconLevelContainer {
  position: relative;
}

.levelContainer {
  position: absolute;
  bottom: size(-4);
  left: size(36);
  display: flex;
  align-items: center;
  justify-content: center;
  width: size(96);
  height: size(32);
  padding: size(6) 0;
  font-size: size(14);
  font-weight: bold;
  color: var(--color-black);
  background-color: var(--color-lightgray);
  border-radius: size(16);
}

.icon {
  width: size(168);
  height: size(168);
  border: size(4) solid var(--color-lightgray);
  border-radius: 50%;
}

.name {
  margin-top: size(24);
  font-size: size(16);
  font-weight: bold;
}

.birthday {
  margin-top: size(16);
  font-size: size(12);
}

.linkContainer {
  display: flex;
  flex-flow: column nowrap;
  gap: size(16);
  align-items: center;
  width: 100%;
  margin-top: size(48);
}

.link {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: size(16);
  font-size: size(14);
  border-bottom: size(1) solid var(--color-lightgray);
}

.logoutButton {
  display: flex;
  gap: size(8);
  align-items: center;
  justify-content: center;
  padding: size(8) size(16);
  margin-top: size(80);
  font-size: size(12);
  font-weight: bold;
  background-color: var(--color-white);
  border-radius: size(4);
}

.iconLogout {
  width: size(12);
  height: size(16);
  margin-right: size(8);
}
